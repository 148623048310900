import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { addDays, format, parse } from 'date-fns';
import Config from '../../utils/config';

import FinalSummaryContainer from '../../containers/FinalSummaryContainer';
import SwitchContainer from '../../containers/SwitchContainer';
import WordingConstant from '../../utils/wording.json';
import Screen from '../screen';

import * as paymentActions from '../../containers/PaymentModal/PaymentModal.actions';
import { logScreen } from '../../utils/firebase';
import { log, TagLogger, TriggerButtonLogger } from '../../utils/logger';
import * as actionsSummary from '../SummaryScreen/SummaryScreen.actions';
import * as actions from './FinalSummaryScreen.actions';
import ValidateTimer from '../../components/ValidateTimer';
import resources from '../../utils/resources';
import { prepareContract } from './Contract.actions';
// import PaymentModal from '../../containers/PaymentModal/PaymentModal';
import {
    isScheduleWithoutPackBillingMode,
    multiplicationInstallmentFrequency,
    prepareEffectiveStartDate,
    offerSimple,
} from '../../utils/helpers';
import SectionLayout from '../../components/SectionLayout/SectionLayout';
import EnvironmentalOptions from '../../components/FinalSummary/EnvironmentalOptions';
import Title from '../../components/Typography/Title';
import { CustomButton } from '../../components';
import SaveDraftModal from './SaveDraftModal';
import { scrollToRefDelay } from '../../utils/scroll';
import { updateFinalSummaryScreenState } from './FinalSummaryScreen.actions';

const Wording = WordingConstant.FinalSummaryScreen;
const SurveyWording = WordingConstant.SurveyScreen;
const DataWording = WordingConstant.DataScreen;
const WordingLinks = WordingConstant.ValidateTimer;

export class FinalSummaryScreen extends React.Component {
    static navigationOptions = {
        nextScreen: Screen.VALIDATION,
        previousScreen: Screen.SITUATION,
        buttonNextTitle: Wording.nextButton,
        buttonPreviousTitle: Wording.previousButton,
        title: Wording.title,
        screenId: Screen.FINALSUMMARY,
        showSaveText: true,
        step: 4,
        validate: () => {
        },
        saveData: () => {
        },
    };

    constructor(props) {
        super(props);
        const { summaryState } = props;
        const initialFields = {
            additionalOptions: [],
            packageID: '',
            prices: [],
            lastPackageID: '',
            lastPrices: [],
        };
        const currentAdvantageOption = Wording.advantage.body.options.find(
            op => op.value === summaryState.advantage.role,
        );

        this.state = {
            // isOpen: this.isSelectra() || false,
            // isConfirmed: false,
            // isCommunicationConfirmed: false,
            isOpen: false,
            timeRemaining: 0,
            loadingContract: false,
            toggle: {
                showInput: currentAdvantageOption !== undefined,
                currentAdvantageOption: currentAdvantageOption || {},
            },
            fields: {
                EL: {
                    ...initialFields,
                    additionalOptions: summaryState.EL.additionalOptions,
                },
                NG: {
                    ...initialFields,
                    additionalOptions: summaryState.NG.additionalOptions,
                },
                thirdParty: {
                    code: summaryState.advantage.code,
                    role: summaryState.advantage.role,
                    message: '',
                    error: false,
                    loading: false,
                },
            },
            isValidEffectiveStartDate: this.checkEffectiveStartDate(),
            // showSaveDraftModal: false,
        };
        this.myRef = createRef();
    }

    intervalId = null;

    componentDidMount() {
        if (this.props.timeUnblock) {
            const timeUnblock = this.props.timeUnblock;
            const currentTime = Math.floor(Date.now() / 1000);
            const timeRemaining = timeUnblock - currentTime;

            this.setState({ timeRemaining });

            this.intervalId = setInterval(this.updateTimeRemaining, 1000);
        }
        logScreen(TagLogger.FINALSUMMARY);
        const {
            userState: { energyTypes },
            dataState: {
                common: { dateContract },
            },

            checkContractPaymentCBDispatch,
        } = this.props;
        const { fields } = this.state;
        FinalSummaryScreen.navigationOptions.saveData = () => ({
            thirdParty: fields.thirdParty,
            logger: {
                tag: TagLogger.FINALSUMMARY,
                triggerButton: TriggerButtonLogger.SAVE_BTN,
            },
            mergeProps: true,
        });

        checkContractPaymentCBDispatch({ energyTypes, dateContract });
        FinalSummaryScreen.navigationOptions.validate = () =>
            this.handleValidateScreen();

        const { handleGoNext } = this.props;
        if (handleGoNext) {
            handleGoNext(true);
        }
        scrollToRefDelay(this.myRef, 200);
    }

    componentWillUnmount() {
        // Nettoie l'intervalle lorsqu'il n'est plus nécessaire
        clearInterval(this.intervalId);
    }

    updateTimeRemaining = () => {
        this.setState(prevState => {
            const newTimeRemaining = prevState.timeRemaining - 1;

            if (newTimeRemaining <= 0) {
                // Le temps est écoulé, ferme la popin
                this.handelShowModal(false); // Décommentez cette ligne
                clearInterval(this.intervalId); // Arrête l'intervalle
            }

            return { timeRemaining: newTimeRemaining };
        });
    };

    handelShowModal = bool => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        this.setState({ isOpen: bool });
    };

    formatTime = seconds => {
        // Convertit les secondes en format "mm:ss"
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${
            remainingSeconds < 10 ? '0' : ''
        }${remainingSeconds}`;
    };

    handleValide = () => {
        if (
            this.state.timeRemaining === null ||
            this.state.timeRemaining <= 0
        ) {
            this.handelShowModal(false);
            this.props.nextScreen();
        } else {
            this.handelShowModal(true);
        }
    };

    componentDidUpdate() {
        const {
            paymentState: { isReferenceRemoved },
            setReferenceDispatch,
        } = this.props;
        if (isReferenceRemoved) {
            window.history.replaceState(null, null, window.location.pathname);
            setReferenceDispatch({});
        }

        // if (isDirectDebitSuccess) {
        //     FinalSummaryScreen.navigationOptions.validate = () => {
        //         // this.callOrderStatus();
        //         // const { handleGoNext } = this.props;
        //         // if (handleGoNext)
        //         //     handleGoNext(true);
        //         console.log("true")
        //     };
        // }
    }

    callOrderStatus = () =>
        // const {
        //     paymentState: { currentOrder, externalReference, reference },
        //     validatePaymentScreenDispatch,
        // } = this.props;

        // const prepareOrder = {
        //     ...currentOrder,
        //     externalReference,
        //     orderReference: reference,
        // };

        // validatePaymentScreenDispatch({
        //     reference,
        //     prepareOrder,
        //     // logger: {
        //     //     tag: TagLogger.FINALSUMMARY,
        //     //     triggerButton: TriggerButtonLogger.VALIDATE_BTN,
        //     // },
        // });
        true;

    isSelectra = () => {
        const channel = localStorage.getItem('channel');

        return channel !== undefined && channel === 'SELECTRA';
    };

    channel = () => localStorage.getItem('channel');

    handleModalToggle = (type, val) => {
        this.setState({ [type]: val });
    };

    handleValidateScreen = () => {
        const {
            fields: { thirdParty },
            isValidEffectiveStartDate,
        } = this.state;
        const {
            userState,
            dataState,
            summaryState,
            paymentState,
            updateFinalSummaryScreenStateDispatch,
            redirectToDataScreenDispatch,
        } = this.props;

        if (!isValidEffectiveStartDate) {
            return redirectToDataScreenDispatch();
        }

        // Créez le payload avec les données nécessaires
        const payload = {
            ...userState,
            ...dataState,
            ...summaryState,
            ...paymentState,
            thirdParty,
        };

        // Dispatch l'action pour mettre à jour le state Redux
        updateFinalSummaryScreenStateDispatch(payload);

        return true;
    };


    handleToggle = ({ target: { value, checked } }, type) => {
        const {
            userState: { userType },
            summaryState,
            changeOfferDispatch,
            changeProductOptionDispatch,
        } = this.props;
        const offer = summaryState.offers.find(
            off =>
                off.energy === type &&
                (checked ? !off.product.default : off.product.default),
        );
        if (offer) {
            const options = checked ? [value] : [];
            changeOfferDispatch({
                offer,
                options,
                energyType: type,
            });
        } else {
            const productCode = checked
                ? value
                : (
                summaryState.products.find(
                    p => p.energy === type && p.default,
                ) || {}
            ).productCode || '';

            changeProductOptionDispatch({
                ...summaryState,
                userType,
                productCode,
                energyType: type,
                contracts: summaryState.contracts.filter(
                    p => p.energy === type,
                ),
                logger: {
                    tag: TagLogger.FINALSUMMARY,
                    triggerButton: TriggerButtonLogger.FIXED_PRICE,
                },
            });
        }
    };

    handleToggleAdditionalOptions = ({ target: { value } }) => {
        const { fields } = this.state;
        const {
            userState: { energyTypes },
            isCurrentScreen,
            resetScreenTo,
        } = this.props;
        if (!isCurrentScreen) resetScreenTo();
        energyTypes.forEach(energy => {
            const currentOptions = fields[energy].additionalOptions;
            if (currentOptions.includes(value)) {
                fields[energy].additionalOptions = currentOptions.filter(
                    option => option !== value,
                );
            } else {
                currentOptions.push(value);
            }
            const { changeAdditionalRatesDispatch } = this.props;
            log({
                tag: TagLogger.FINALSUMMARY,
                returnCode: null,
                logMessage: null,
                serviceCalled: null,
                statusAction: null,
                triggerButton: `option-${value}`,
            });

            changeAdditionalRatesDispatch({
                additionalOptions: fields[energy].additionalOptions,
                energyType: energy,
            });
            this.setState({ fields });
        });
    };

    handleChangeFields = (prices, packageID, energy) => {
        const { fields } = this.state;
        fields[energy].prices = prices;
        fields[energy].packageID = packageID;

        const {
            changePackagesDispatch,
            packageState,
            summaryState,
        } = this.props;
        let packages = [];
        let id = packageID;
        if (packageID === '') {
            fields[energy].lastPackageID = packageID;
            id = summaryState[energy].packageID;
            const currentPackage =
                packageState.packages.find(p => p.id === id) ||
                packageState.packages.find(p => p.recommended);

            if (currentPackage) {
                packages = this.findPackages(
                    currentPackage.authorizedPackages,
                    prices,
                );

                id = currentPackage.id;
            }
        } else {
            fields[energy].lastPrices = prices;
            packages = packageState.packages
                ? packageState.packages.filter(
                    p => p.energy === energy && p.id === packageID,
                )
                : [];
        }

        changePackagesDispatch({ packages, packageID: id, energyType: energy });
        this.setState({ fields });
    };

    handleResetFields = (name, energy) => {
        const { fields } = this.state;
        if (name === 'packageID') {
            fields[energy].packageID = fields[energy].lastPackageID || '';
        } else {
            fields[energy].prices = fields[energy].lastPrices || [];
        }
        this.setState({ fields });
    };

    findPackages = (packages, couple) => {
        const res = (packages &&
            packages.find(
                p =>
                    p &&
                    p.length === 2 &&
                    p[0].amount === `${couple[0]}` &&
                    p[1].amount === `${couple[1]}`,
            )) || [{}, {}];
        return res;
    };

    getDetail = energy => {
        const {
            codeState: { PDL, PCE },
            dataState: {
                common: { dateContract },
            },
            summaryState,
            userState: { energyTypes },
        } = this.props;

        const sections = [
            {
                name: 'providerDetails',
                details: [],
            },
            {
                name: 'startDate',
                details: [],
            },
            {
                name: 'specifications',
                details: [],
            },
            {
                name: 'billingFrequency',
                details: [],
            },
        ];

        const providerDetails = sections.find(_ => _.name === 'providerDetails')
            .details;

        if (energy === 'EL' && PDL !== '') {
            providerDetails.push({
                title: Wording.changeProviderPDL,
                value: PDL,
            });
        } else if (energy === 'NG' && PCE !== '') {
            providerDetails.push({
                title: Wording.changeProviderPCE,
                value: PCE,
            });
        }

        let isCyclic = false;
        for (let i = 0; i < energyTypes.length; i += 1) {
            if (summaryState[energyTypes[i]].cyclicBill === true) {
                isCyclic = true;
                break;
            }
        }

        const { date, month, year } = dateContract[energy];
        const dayLabel = `${date < 10 ? `0${date}` : date}`;
        const monthLabel = `${month + 1 < 10 ? `0${month + 1}` : month + 1}`;
        const dateString = `${dayLabel}/${monthLabel}/${year}`; // format JJ/MM/AAAA
        const dateFormat = 'dd/MM/yyyy';
        const parsedDate = parse(dateString, dateFormat, new Date());
        const addingDays = isCyclic ? 0 : 21
        const totalDate = format(addDays(parsedDate, addingDays), dateFormat);

        const sectionStartDate = sections.find(_ => _.name === 'startDate')
            .details;

        if (dateContract.switch && dateContract.switch.value === 'LATER') {
            sectionStartDate.push({
                title: Wording.beginDate,
                value: totalDate,
            });
        } else {
            sectionStartDate.push({
                title: Wording.beginDate,
                value: `${dayLabel}/${monthLabel}/${year}`,
            });
        }


        const contract =
            summaryState.contracts &&
            summaryState.contracts.find(c => c && c.energy === energy);
        if (contract === undefined) {
            return sections;
        }
        const { deliveryPoint } = contract;
        const readingFrequency = (
            Wording.frequency.values.find(
                v => v.value === deliveryPoint.readingFrequency,
            ) || {}
        ).text;

        const installmentFrequency = (
            Wording.frequency.values.find(
                v => v.value === summaryState[energy].installmentFrequency,
            ) || {}
        ).text;
        const settlementFrequency = summaryState[energy].cyclicBill
            ? Wording.settlementFrequencyValue.scheduleBill
            : Wording.settlementFrequencyValue.cyclicBill;

        let separateDetail;
        if (energy === 'EL') {
            const priceOption = (
                SurveyWording.priceOption.values.find(
                    v => v.value === contract.rateOption,
                ) || {}
            ).text;
            separateDetail = [
                {
                    title: Wording.contractualCapacityEL,
                    value: `${deliveryPoint.contractualCapacity} ${deliveryPoint.contractualCapacityUnit}`,
                },
                {
                    title: Wording.meterReading,
                    value: deliveryPoint.meterNumber,
                },
                {
                    title: Wording.priceOption,
                    value: priceOption,
                },
            ];
        } else {
            separateDetail = [
                {
                    title: Wording.profile,
                    value: deliveryPoint.profile,
                },
                {
                    title: Wording.meterReading,
                    value: deliveryPoint.meterNumber,
                },
                {
                    title: Wording.priceOption,
                    value: deliveryPoint.gridRate,
                },
            ];
            if (contract.estimates && contract.estimates.length > 0) {
                separateDetail.unshift({
                    title: Wording.contractualCapacityNG,
                    value: `${contract.estimates[0].quantity} ${Wording.contractualCapacityUnitNG}`,
                });
            }
        }

        const sectionSpecifications = sections.find(
            _ => _.name === 'specifications',
        ).details;
        separateDetail.forEach(_ => sectionSpecifications.push(_));

        const sectionBillingFrequency = sections.find(
            _ => _.name === 'billingFrequency',
        ).details;

        sectionBillingFrequency.push({
            title: Wording.readingFrequency,
            value: readingFrequency,
        });

        sectionBillingFrequency.push({
            title: Wording.installmentFrequency,
            value: installmentFrequency,
        });

        sectionBillingFrequency.push({
            title: Wording.settlementFrequency,
            value: settlementFrequency,
        });

        return sections;
    };

    transformAuthorizedPackages = packages => {
        const res =
            packages &&
            packages
                .map(p => {
                    if (p && p.length === 2) {
                        return p
                            .sort((x, y) =>
                                x.timeframe < y.timeframe ? -1 : 1,
                            )
                            .map(x => parseInt(x.amount, 0));
                    }
                    return [0, 0];
                })
                .sort((x, y) => (x[0] < y[0] ? -1 : 1));
        return res;
    };

    returnParentingCode = (value, valid) => {
        const { fields } = this.state;

        if (fields.thirdParty.role === 'SPONSOR') {
            return valid ? value : fields.thirdParty.code;
        }
        return value;
    };

    handleChangeInputAdvantageCode = ({ target: { value, validity } }) => {
        const { fields } = this.state;
        fields.thirdParty = {
            ...fields.thirdParty,
            code: this.returnParentingCode(value, validity.valid),
        };
        this.setState({ fields });
    };

    handleChangeSelectAdvantageCode = value => {
        const { toggle, fields } = this.state;
        const {
            summaryState: { advantage },
        } = this.props;
        toggle.currentAdvantageOption = value;
        fields.thirdParty = {
            code: value.value === advantage.role ? advantage.code : '',
            role: value.value,
            message: '',
            error: false,
            loading: false,
        };
        this.setState({ toggle, fields });
    };

    handleShowInputAdvantageCode = () => {
        const { toggle, fields } = this.state;
        const {
            summaryState: { advantage },
        } = this.props;
        toggle.showInput = !toggle.showInput;
        toggle.currentAdvantageOption =
            Wording.advantage.body.options.find(
                op => op.value === advantage.role,
            ) || {};
        fields.thirdParty = {
            code: toggle.showInput ? advantage.code : '',
            role: toggle.showInput ? advantage.role : '',
            message: '',
            error: false,
            loading: false,
        };
        this.setState({ toggle, fields });
    };

    isPaymentCB = () => {
        const {
            summaryState: { contracts },
        } = this.props;

        return contracts.reduce(
            (acc, { firstPaymentCB }) =>
                acc || (firstPaymentCB !== undefined ? firstPaymentCB : false),
            false,
        );
    };

    checkEffectiveStartDate = () => {
        const {
            summaryState: { contracts },
            dataState: {
                common: { dateContract },
            },
        } = this.props;

        return contracts.reduce(
            (acc, curr) => {
                const { date, month, year } = dateContract[curr.energy];
                const d = new Date(year, month, date);
                const isSwitch = dateContract.switch;
                const nextDate = isSwitch
                    ? prepareEffectiveStartDate(
                        d,
                        curr.energy,
                        dateContract.switch.value,
                    )
                    : d;
                return acc || nextDate.getTime() > new Date().getTime();
            },

            false,
        );
    };

    prepareDataToConfirmationModal = () => {
        const {
            userState: { energyTypes },
            summaryState,
        } = this.props;

        const reduce = (acc, current) => {
            const {
                installmentFrequency,
                chosenPackages,
                cyclicBill,
            } = summaryState[current];

            if (!cyclicBill) {
                if (chosenPackages.length > 1) {
                    return [
                        ...acc,
                        {
                            energy: current,
                            type: 'SUMMER_WINTER',
                        },
                    ];
                }
                const sumAmountByPackage = chosenPackages.reduce(
                    (a, c) => parseFloat(c.amount) + a,
                    0,
                );
                const sumAmountByPackageFrequency = multiplicationInstallmentFrequency(
                    installmentFrequency,
                    sumAmountByPackage,
                );

                return [
                    ...acc,
                    {
                        energy: current,
                        sumAmountByPackage,
                        sumAmountByPackageFrequency,
                        installmentFrequency,
                        type: 'BASE',
                    },
                ];
            }

            return [
                ...acc,
                {
                    energy: current,
                    type: 'CYCLIC_BILL',
                },
            ];
        };

        return energyTypes.reduce(reduce, []);
    };

    renderSwitch() {
        const {
            toggle: { showInput, currentAdvantageOption },
            fields: { thirdParty },
        } = this.state;
        const { summaryState } = this.props;
        const { options } = Wording.advantage.body;
        const switchProps = {
            showInput,
            toggleTitle: Wording.advantage.title,
            thirdParty: {
                code: thirdParty.code,
                message: thirdParty.message,
                error: thirdParty.error,
                loading: thirdParty.loading,
            },
            select: {
                options,
                currentValue: currentAdvantageOption,
                defaultValue: currentAdvantageOption,
                handleChange: this.handleChangeSelectAdvantageCode,
            },
            handleShowInput: this.handleShowInputAdvantageCode,
            handleChangeInput: this.handleChangeInputAdvantageCode,
            contracts: summaryState.contracts,
        };
        return <SwitchContainer {...switchProps} />;
    }

    // renderEnvironmentalOptions() {}

    renderBody(energy, index) {
        const {
            userState: { energyTypes },
            summaryState,
            packageState,
            dataState: { common },
        } = this.props;
        const { fields } = this.state;
        const s = summaryState[energy];
        const offer = s.offer;
        const productOptions = s.options;
        const details = this.getDetail(energy);
        const additionalRatesList =
            (offer &&
                offer.product &&
                offer.product.additionalRates &&
                offer.product.additionalRates.filter(
                    a => a.type === 'OPTION',
                )) ||
            [];
        const packages = packageState.packages
            ? packageState.packages.filter(p => p.energy === energy)
            : [];
        const prices = s.chosenPackages
            ? s.chosenPackages.map(p => p.amount)
            : [];
        const consumption = s.chosenPackages
            ? s.chosenPackages.map(p => p.quantityMonthly)
            : [];
        const products = summaryState.products
            ? summaryState.products.filter(
                product => !product.default && product.energy === energy,
            )
            : [];
        const currentPackage =
            packages.find(p => p.id === summaryState[energy].packageID) ||
            packages.find(p => p.recommended) ||
            {};
        const ranges = this.transformAuthorizedPackages(
            currentPackage.authorizedPackages,
        );

        return (
            <FinalSummaryContainer
                key={index}
                prices={prices}
                offer={offer}
                consumption={consumption}
                cyclicBill={summaryState[energy].cyclicBill}
                currentPackage={currentPackage}
                packages={packages}
                ranges={ranges}
                products={products}
                currentOptions={productOptions}
                currentAdditionalOptions={fields[energy].additionalOptions}
                options={additionalRatesList}
                handleToggle={e => this.handleToggle(e, energy)}
                handleToggleAdditionalOptions={e =>
                    this.handleToggleAdditionalOptions(e, energy)
                }
                handleChangeFields={(ps, id) =>
                    this.handleChangeFields(ps, id, energy)
                }
                handleResetFields={name => this.handleResetFields(name, energy)}
                loadingOption={s.loadingOption}
                energy={energy}
                details={details}
                userInfo={common}
                showSeparator={index + 1 < energyTypes.length}
            />
        );
    }

    handleFilePdf = async () => {
        try {
            const pdfUrl = '/static/Document d\'informations.pdf';
            const encodedUrl = encodeURI(pdfUrl);
            const correctedUrl = encodedUrl.replace(/(%2F|%20)/g, match => match === '%2F' ? '/' : ' ');

            window.open(
                correctedUrl,
                '_blank',
                'noopener noreferrer',
            );


        } catch (e) {
            console.error('Impossible de récupérer le fichier pdf', e);
        }
    };

    renderHeader() {
        const {
            dataState: {
                common: { civility, name, surname, address, newAddress },
            },
        } = this.props;
        const civilityLabel = (
            DataWording.civility.values.find(v => v.value === civility) || {}
        ).label;
        const person = {
            name: `${civilityLabel} ${name} ${surname}`,
            address: `${newAddress.address ||
            address.address} ${newAddress.postalCode ||
            address.postalCode} ${newAddress.city || address.city}`,
        };
        return (
            <div className="final-summary-screen__header">
                <p>{person.name}</p>
                <p>{person.address}</p>
            </div>
        );
    }

    handleContract = async () => {
        try {
            this.setState({ loadingContract: true });
            const reduxStateString = localStorage.getItem('reduxState');
            if (reduxStateString) {
                const reduxState = JSON.parse(reduxStateString);
                const payloadContract = prepareContract(reduxState);

                if (
                    Array.isArray(payloadContract) &&
                    payloadContract.length > 0
                ) {
                    payloadContract.map(async (contract, index) => {
                        try {
                            const headers = {
                                'x-api-key': Config.ApiKey,
                            };

                            const res = await fetch(
                                `${Config.URL}/getContract`,
                                {
                                    method: 'POST',
                                    body: JSON.stringify(contract),
                                    headers,
                                },
                            );

                            if (res.ok) {
                                const blob = await res.blob();
                                const fileURL = URL.createObjectURL(blob);
                                this.setState({ loadingContract: false });
                                window.open(fileURL, `_blank_${index}`);
                            } else {
                                this.setState({ loadingContract: false });
                                console.error(
                                    `La requête a échoué avec le statut ${res.status}`,
                                );
                            }
                        } catch (error) {
                            this.setState({ loadingContract: false });
                            console.error(
                                `Erreur lors de la récupération du contrat (index ${index}):`,
                                error,
                            );
                        }
                    });
                }
            }
        } catch (error) {
            console.error(
                'Erreur générale lors de la récupération des contrats :',
                error,
            );
        }
    };

    render() {
        const {
            userState: { energyTypes },
            // paymentState: { iframe, stripe },
            packageState: { autorizedBillingModes },
            summaryState,
            handleSave,
            mainState: { loading, isRecover },
            isCurrentScreen,
            isModalOpen,
        } = this.props;
        const isScheduleWithoutPack = isScheduleWithoutPackBillingMode(
            autorizedBillingModes,
        );
        let isCyclic = false;
        for (let i = 0; i < energyTypes.length; i += 1) {
            if (summaryState[energyTypes[i]].cyclicBill === true) {
                isCyclic = true;
                break;
            }
        }
        const {
            // isOpen,
            // isConfirmed,
            // isCommunicationConfirmed,
            fields,
            loadingContract,
            // isValidEffectiveStartDate,
        } = this.state;
        const payloadContract = prepareContract(
            JSON.parse(localStorage.getItem('reduxState')),
        );
        return (
            <>
                <div ref={this.myRef} />
                <SectionLayout step={isCyclic ? 7 : 8} isModal={isModalOpen}>
                    <div className="final-summary-screen">
                        <div className="final-summary-screen__environment">
                            <div className="final-summary-screen__environment__title">
                                <Title
                                    titleFormatGowun="Un petit geste "
                                    titleFormatLexand="pour la planète ?"
                                />
                            </div>

                            <div className="final-summary-screen__environment__content">
                                <EnvironmentalOptions
                                    summaryState={summaryState}
                                    energyTypes={energyTypes}
                                    fields={fields}
                                    handleToggleAdditionalOptions={e =>
                                        this.handleToggleAdditionalOptions(e)
                                    }
                                />
                            </div>
                        </div>
                        <div className="final-summary-screen__recap">
                            <div className="final-summary-screen__recap__title">
                                <Title
                                    titleFormatGowun="Mon "
                                    titleFormatLexand="récapitulatif final !"
                                />
                            </div>
                            {energyTypes &&
                                energyTypes.map((energy, index) =>
                                    this.renderBody(energy, index),
                                )}
                            {!isScheduleWithoutPack && this.renderSwitch()}
                            <div className="final-summary-container-infos">
                                <div>
                                    <img
                                        className="validate-timer-img-download"
                                        src={resources['ic-download-timer']}
                                        alt="icon"
                                    />
                                    Avant de valider, prends bien connaissance
                                    de tes documents :
                                </div>

                                <p className="validate-timer-link">
                                    {WordingLinks.popup.bottomLinks
                                        .filter(
                                            link =>
                                                link.text &&
                                                (link.link || link.api),
                                        )
                                        .map((link, i) => {
                                            if (
                                                (link.value.includes(
                                                        'INFORMATION_DOCUMENT',
                                                    ) ||
                                                    link.value.includes(
                                                        'GET_CONTRACT',
                                                    )) &&
                                                isCyclic === true
                                            ) {
                                                return null;
                                            }
                                            if (
                                                (link.value.includes(
                                                        'INFORMATION_DOCUMENT',
                                                    )) && offerSimple(summaryState.offers) === true
                                            ) {
                                                return null;
                                            }
                                            if (
                                                link.value ===
                                                'INFORMATION_DOCUMENT' &&
                                                (Config.ChannelInformationDocument.includes(
                                                        this.channel(),
                                                    ) ||
                                                    !this.channel())
                                            ) {
                                                return null;
                                            }
                                            if (
                                                link.value ===
                                                'INFORMATION_DOCUMENT'
                                            ) {
                                                return (
                                                    <span
                                                        className="validate-timer-link"
                                                        key={`linkFooterBottom${i}`}
                                                        onClick={
                                                            this.handleFilePdf
                                                        }
                                                        rel="noopener noreferrer"
                                                    >
                                                        {link.text}
                                                    </span>
                                                );
                                            }
                                            if (link.link) {
                                                return (
                                                    <a
                                                        className="validate-timer-link"
                                                        key={`linkFooterBottom${i}`}
                                                        href={link.link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {link.text}
                                                    </a>
                                                );
                                            }
                                            if (link.api) {
                                                const isSingleContract =
                                                    Array.isArray(
                                                        payloadContract,
                                                    ) &&
                                                    payloadContract.length ===
                                                    1;
                                                const linkText = isSingleContract
                                                    ? 'Ton contrat'
                                                    : 'Tes contrats';
                                                if (loadingContract) {
                                                    return (
                                                        <>
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faCircleNotch
                                                                }
                                                                spin
                                                                size="lg"
                                                                style={{
                                                                    color:
                                                                        'rgb(81 81 81)',
                                                                }}
                                                            />
                                                            <span
                                                                style={{
                                                                    color:
                                                                        '#6f49ce',
                                                                }}
                                                            >
                                                                &nbsp;|
                                                            </span>
                                                        </>
                                                    );
                                                }
                                                return (
                                                    <span
                                                        className="validate-timer-link__contract"
                                                        key={`linkFooterBottom${i}`}
                                                        onClick={
                                                            this.handleContract
                                                        }
                                                    >
                                                        {linkText} |
                                                    </span>
                                                );
                                            }
                                            return (
                                                <React.Fragment
                                                    key={`linkFooterBottom${i}`}
                                                />
                                            );
                                        })}
                                </p>
                            </div>
                            {isCurrentScreen && (
                                <div className="final-summary-screen__actions">
                                    <CustomButton
                                        title="Valider"
                                        color="orange"
                                        className="final-summary-screen__actions__validate"
                                        onClick={() => this.handleValide()}
                                        loading={loading}
                                    />
                                    {this.state.timeRemaining > 0 && (
                                        <img
                                            className="validate-timer-image-finalscreen"
                                            src={resources['ic-logo-timer']}
                                            alt="icon"
                                        />
                                    )}

                                    <ValidateTimer
                                        isOpen={this.state.isOpen}
                                        timeRemaining={this.state.timeRemaining}
                                    />

                                    {!isRecover && (
                                        <>
                                            <CustomButton
                                                title="Enregistrer mes données"
                                                color="gray"
                                                onClick={() => handleSave()}
                                                loading={loading}
                                            />
                                            <p className="subtitle-code">
                                                Enregistrer mes données : un
                                                lien t’est envoyé par mail afin
                                                de te permettre de poursuivre ta
                                                souscription à tout moment.
                                                <br />
                                                Attention : l’offre est
                                                sauvegardée durant 7 jours
                                                uniquement.
                                            </p>
                                        </>
                                    )}
                                </div>
                            )}
                            {/* {((isConfirmed && isCommunicationConfirmed) ||
                            iframe !== null ||
                            stripe !== null) && <PaymentModal />} */}

                            {/* <ConfirmationModal
                    isConfirmed={isConfirmed}
                    isCommunicationConfirmed={isCommunicationConfirmed}
                    paymentCB={this.isPaymentCB()}
                    show={isOpen}
                    handleToggle={this.handleModalToggle}
                    handleValidateScreen={this.handleValidateScreen}
                    data={this.prepareDataToConfirmationModal()}
                    isValidEffectiveStartDate={isValidEffectiveStartDate}
                    isScheduleWithoutPack={isScheduleWithoutPack}
                /> */}
                            {!isRecover && <SaveDraftModal />}
                        </div>
                    </div>
                </SectionLayout>
            </>
        );
    }
}

const mapStateToProps = state => ({
    userState: state.userReducer,
    codeState: state.codeReducer,
    packageState: state.packageReducer,
    summaryState: state.summaryReducer,
    dataState: state.dataReducer,
    paymentState: state.paymentReducer,
    mainState: state.mainReducer,
    timeUnblock: state.paymentReducer.timeUnblock,
});

const mapDispatchToProps = dispatch => ({
    validatePaymentScreenDispatch: payload =>
        dispatch(paymentActions.validatePaymentScreen(payload)),
    validateFinalSummaryScreenDispatch: payload =>
        dispatch(actions.validateFinalSummaryScreen(payload)),
    updateFinalSummaryScreenStateDispatch: payload => dispatch(updateFinalSummaryScreenState(payload)),
    checkContractPaymentCBDispatch: payload =>
        dispatch(actionsSummary.checkContractPaymentCB(payload)),
    changePackagesDispatch: payload =>
        dispatch(actions.changePackages(payload)),
    redirectToDataScreenDispatch: () =>
        dispatch(actions.redirectToDataScreen()),
    changeAdditionalRatesDispatch: payload =>
        dispatch(actions.changeAdditionalRates(payload)),
    changeProductOptionDispatch: payload =>
        dispatch(actionsSummary.changeProductOption(payload)),
    changeOfferDispatch: payload =>
        dispatch(actionsSummary.changeOffer(payload)),
    setReferenceDispatch: payload => dispatch(actions.setReference(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinalSummaryScreen);
